<template>
  <div class="pb-2">
    <mark-supllier-drivings-as-invoiced-popup
      ref="MarkSullierDrivingsAsInvoicedPopup"
    />
    <b-card no-body>
      <div class="p-2 d-flex justify-content-between">
        <div class="d-flex justify-content-start">
          <div class="mr-2">
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodeFrom", "Periode from")
            }}</label>
            <b-form-datepicker
              v-model="fromDate"
              placeholder="YYYY-MM-DD"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </div>
          <div>
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodeTo", "Periode to")
            }}</label>
            <b-form-datepicker
              v-model="toDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between align-items-right">
            <b-button class="mr-1" @click="exportData" size="sm"
              >Eksporter periode</b-button
            >
            <b-button
              class="mr-1"
              variant="primary"
              :disabled="selectedTransportIds.length == 0"
              @click="handleMarkAsInvoicedClicked"
            >
              Marker som faktureret
            </b-button>
            <span class="total-price"
              >Total valgte:
              <span class="text-primary">{{
                totalTablePrice | toCurrency
              }}</span></span
            >
          </div>
        </div>
      </div>
      <page-loader :loading="loading">
        <div class="table-con">
          <b-table-simple small>
            <b-thead>
              <b-tr class="toggle_headers">
                <b-th>
                  <b-form-checkbox
                    class="mt-25"
                    name="is-rtl"
                    inline
                    @change="toggleSelectDeselectAll"
                    v-model="selectAllActive"
                  />
                </b-th>
                <b-th>Transport id</b-th>
                <b-th>Status</b-th>
                <b-th>Type</b-th>
                <b-th>Påbegyndt</b-th>
                <b-th>Afsluttet</b-th>
                <b-th>Vogn</b-th>
                <b-th>Chauffør</b-th>
                <b-th>Rekvisitionsnummer</b-th>
                <b-th>Bestillingstidspunkt</b-th>
                <b-th>Fraktion</b-th>
                <b-th>Kg kørt</b-th>
                <b-th>Afhentingssted</b-th>
                <b-th>Modtageranlæg</b-th>
                <b-th>Pris</b-th>
                <b-th>Tillægspris</b-th>
                <b-th>Total pris</b-th>
                <b-th>Faktureret i</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(transport, index) in transports">
                <b-tr
                  :key="`${transport.id}_1`"
                  :class="{
                    'light-gray': index % 2 == 0,
                    'table-secondary': transport.invoiceNumber != null,
                    'table-primary': selectedTransportIds.includes(
                      transport.id
                    ),
                  }"
                >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">
                    <b-form-checkbox
                      class="mt-25"
                      :checked="selectedTransportIds.includes(transport.id)"
                      @change="toggleSelectedTransportForId(transport.id)"
                      inline
                      :disabled="transport.invoiceNumber != null"
                    />
                  </b-td>
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">{{
                    transport.transportId
                  }}</b-td>
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><b-badge
                      :variant="
                        transport.status == 'OnGoing' ? 'primary' : 'secondary'
                      "
                      >{{ getStatusText(transport.status) }}</b-badge
                    ></b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{ getTypeText(transport.type) }}</small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{
                      format_datetime(transport.startTime)
                    }}</small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{
                      format_datetime(transport.closedAt)
                    }}</small></b-td
                  >

                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{
                      transport.autoGenerated
                        ? "--"
                        : transport.transportVehicleNumber
                    }}</small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{
                      transport.autoGenerated
                        ? "Autogenereret af system"
                        : transport.driverName
                    }}</small></b-td
                  >
                  <b-td
                    ><small
                      ><a
                        href="#"
                        @click="
                          handleShowOrderClicked(transport.primaryOrder_Id)
                        "
                        >{{ transport.primaryOrder_RequisitionId }}</a
                      ></small
                    ></b-td
                  >
                  <b-td
                    ><small>{{
                      format_datetime(transport.primaryOrder_OrderTime)
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.primaryOrder_FractionName
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.primaryOrder_KgCollected | toCurrency
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.primaryOrder_PickupLocationName
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.primaryOrder_ReceivingFacilityName
                    }}</small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Double' ? 2 : 1">
                    {{ transport.primaryOrder_Price | toCurrency }}</b-td
                  >
                  <b-td :rowspan="transport.type == 'Double' ? 2 : 1">
                    {{
                      transport.primaryOrder_AdditionalPrice | toCurrency
                    }}</b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">
                    {{ transport.totalPrice | toCurrency }}</b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">
                    <small>{{
                      transport.invoiceNumber != null
                        ? transport.invoiceNumber
                        : "Ej faktureret"
                    }}</small></b-td
                  >
                </b-tr>
                <b-tr
                  :key="`${transport.id}_2`"
                  v-if="transport.type != 'Solo'"
                  :class="{
                    'light-gray': index % 2 == 0,
                    'table-secondary': transport.invoiceNumber != null,
                    'table-primary': selectedTransportIds.includes(
                      transport.id
                    ),
                  }"
                >
                  <b-td style="padding-left: 0.5rem"
                    ><small>
                      <a
                        href="#"
                        @click="
                          handleShowOrderClicked(transport.secondaryOrder_Id)
                        "
                        >{{ transport.secondaryOrder_RequisitionId }}</a
                      >
                    </small></b-td
                  >
                  <b-td
                    ><small>{{
                      format_datetime(transport.secondaryOrder_OrderTime)
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_FractionName
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_KgCollected | toCurrency
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_PickupLocationName
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_ReceivingFacilityName
                    }}</small></b-td
                  >
                  <b-td
                    v-if="
                      transport.type != 'Solo' && transport.type != 'Double'
                    "
                    >{{ transport.secondaryOrder_Price | toCurrency }}</b-td
                  >
                  <b-td
                    v-if="
                      transport.type != 'Solo' && transport.type != 'Double'
                    "
                    >{{
                      transport.secondaryOrder_AdditionalPrice | toCurrency
                    }}</b-td
                  >
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
      </page-loader>
    </b-card>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import {
  BCard,
  BContainer,
  BFormDatepicker,
  BFormCheckbox,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BBadge,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import moment from "moment";
import ResourceService from "@/services/base/resource.service";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import MarkSupllierDrivingsAsInvoicedPopup from "./components/MarkSupllierDrivingsAsInvoicedPopup.vue";

export default {
  components: {
    BTableSimple,
    BFormDatepicker,
    BFormCheckbox,
    BContainer,
    BCard,
    BButton,
    BThead,
    BTbody,
    BBadge,
    BTr,
    BTh,
    BTd,
    PageLoader,
    MarkSupllierDrivingsAsInvoicedPopup,
  },
  data() {
    return {
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      transports: [],
      loading: true,
      selectedTransportIds: [],
      selectAllActive: false,
    };
  },
  async created() {
    await this.fetchTransports();
  },
  computed: {
    totalTablePrice() {
      let transportsToInclude = this.transports.filter((t) =>
        this.selectedTransportIds.includes(t.id)
      );
      return transportsToInclude.reduce(
        (prev, curr) => (prev += curr.totalPrice),
        0
      );
    },
  },
  methods: {
    async fetchTransports() {
      this.loading = true;
      const service = new ResourceService("suppliers/drivings");
      this.transports = (
        await service.get("", { fromDate: this.fromDate, toDate: this.toDate })
      ).data.data;
      this.selectedTransportIds = [];

      this.loading = false;
    },
    getStatusText(statusCode) {
      switch (statusCode) {
        case "Unknown":
          return "Ukendt";
        case "Closed":
          return "Afsluttet";
        case "OnGoing":
          return "Igang";
      }
    },
    getTypeText(typeCode) {
      switch (typeCode) {
        case "Unknown":
          return "Ukendt";
        case "Solo":
          return "Solo";
        case "Double":
          return "Dobbelt";
        case "DoubleMultipleOrigins":
          return "Dobbelt (2 pladser)";
        case "DoubleMultipleDestinations":
          return "Dobbelt (2 modtageranlæg)";
        case "DoubleMultipleOriginsAndDestinations":
          return "Dobbelt (2 pladser & 2 modtageranlæg)";
      }
    },
    async handleShowOrderClicked(orderId) {
      const routeData = this.$router.resolve({
        name: "suppliers.orders.show",
        params: { id: orderId },
      });
      window.open(routeData.href, "_blank");
    },
    exportData() {
      const fileName = `${this.fromDate}_${this.toDate}.xlsx`;

      const modifiedData = this.transports.map((item) => {
        return {
          "Transport id": item.transportId,
          Status: this.getStatusText(item.status),
          Type: this.getTypeText(item.type),
          Påbegyndt: this.format_datetime(item.startTime),
          Afsluttet: this.format_datetime(item.closedAt),
          Vogn: item.autoGenerated ? "--" : item.transportVehicleNumber,
          Chauffør: item.autoGenerated
            ? "Autogenereret af system"
            : item.driverName,
          "Container 1 | Rekvisitionsnummer": item.primaryOrder_RequisitionId,
          "Container 1 | Bestillingstidspunkt": this.format_datetime(
            item.primaryOrder_OrderTime
          ),
          "Container 1 | Fraktion": item.primaryOrder_FractionName,
          "Container 1 | Kg kørt": item.primaryOrder_KgCollected,
          "Container 1 | Afhentingssted": item.primaryOrder_PickupLocationName,
          "Container 1 | Modtageranlæg":
            item.primaryOrder_ReceivingFacilityName,

          "Container 2 | Rekvisitionsnummer": item.type != "Solo" ? item.secondaryOrder_RequisitionId : "",
          "Container 2 | Bestillingstidspunkt": item.type != "Solo" ? this.format_datetime(
            item.secondaryOrder_OrderTime
          ) : "",
          "Container 2 | Fraktion":
            item.type != "Solo" ? item.secondaryOrder_FractionName : "",
          "Container 2 | Kg kørt":
            item.type != "Solo" ? item.secondaryOrder_KgCollected : "",
          "Container 2 | Afhentingssted":
            item.type != "Solo" ? item.secondaryOrder_PickupLocationName : "",
          "Container 2 | Modtageranlæg":
            item.type != "Solo"
              ? item.secondaryOrder_ReceivingFacilityName
              : "",
          "Total pris": item.totalPrice.toFixed(2),
          "Faktureret i":
            item.invoiceNumber != null ? item.invoiceNumber : "Ej faktureret",
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, fileName);
    },
    toggleSelectDeselectAll() {
      if (this.selectAllActive) {
        let transportsToInclude = this.transports.filter(
          (t) => t.invoiceNumber == null
        );
        this.selectedTransportIds = transportsToInclude.map((t) => t.id);
      } else {
        this.selectedTransportIds = [];
      }
    },
    toggleSelectedTransportForId(id) {
      if (this.selectedTransportIds.includes(id)) {
        this.selectAllActive = false;
        const indexToRemove = this.selectedTransportIds.indexOf(
          this.selectedTransportIds.find((i) => i == id)
        );
        this.selectedTransportIds.splice(indexToRemove, 1);
      } else {
        this.selectedTransportIds.push(id);
      }
    },
    async handleMarkAsInvoicedClicked() {
      const result = await this.$refs.MarkSullierDrivingsAsInvoicedPopup.open();
      if (!result) return;
      const service = new ResourceService(
        "suppliers/drivings/mark-as-invoiced"
      );
      await service.post("", {
        transportIds: this.selectedTransportIds,
        invoiceNumber: result,
      });
      await this.fetchTransports();
    },
  },
  watch: {
    fromDate() {
      this.fetchTransports();
    },
    toDate() {
      this.fetchTransports();
    },
  },
};
</script>
<style scoped>
.light-gray {
  background: #f8f8f8;
}
.table-con {
  overflow-y: auto;
}
.total-price {
  text-align: right;
  font-size: 23px;
}
</style>