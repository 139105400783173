<template>
  <b-modal
    id="booststrapmodal"
    title="Angiv faktura nummer"
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
  >
    <b-form-input v-model="invoiceNumber" autofocus />
    <template #modal-footer>
      <div class="w-100">
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right ml-1"
            :disabled="invoiceNumber == ''"
            @click="confirm"
          >
            Godkend
          </b-button>
          <b-button
            variant="light"
            class="float-right"
            @click="handleModalHidden"
          >
            {{ T("Web.Generic.Close") }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BModal, BFormInput, BButton } from "bootstrap-vue";

export default {
  components: { BModal, BFormInput, BButton },
  data() {
    return {
      visible: false,
      invoiceNumber: "",
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    async open() {
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    confirm() {
      this.resolvePromise(this.invoiceNumber);
      this.visible = false;
    },
    handleModalHidden() {
      this.visible = false;
      this.resolvePromise(false);
    },
  },
};
</script>